import * as S from './SocialMedia.styled'
import { Section, Container } from '../../../styles/grid.styles'
import { IconEnums } from '../../base'

export interface SocialMediaProps {
  socialMedia: {
    icon: IconEnums
    url: string
  }[]
}

export interface SocialMediaAPIProps {
  value: {
    social_media: {
      twitter: string
      facebook: string
      linkedin: string
      instagram: string
      youtube: string
      app: string
    }
  }
}

export const SocialMedia = (props: SocialMediaProps) => {
  const { socialMedia } = props

  return (
    <Section>
      <Container fullWidthMobile>
        <S.Socials>
          {socialMedia?.map((data: any, idx) => (
            <S.SocialsLink href={data.url} key={idx}>
              <S.Icon name={data.icon} />
            </S.SocialsLink>
          ))}
        </S.Socials>
      </Container>
    </Section>
  )
}

SocialMedia.ApiBlockToProps = (data: SocialMediaAPIProps) => {
  const { value } = data
  let socialMedia = []
  console.log(value)
  if (value.social_media) {
    if (!!value.social_media.facebook) {
      socialMedia.push({
        icon: IconEnums['icon-fb'],
        url: value.social_media.facebook
      })
    }
    if (!!value.social_media.twitter) {
      socialMedia.push({
        icon: IconEnums['twitter-simple'],
        url: value.social_media.twitter
      })
    }
    if (!!value.social_media.linkedin) {
      socialMedia.push({
        icon: IconEnums['icon-linkedin'],
        url: value.social_media.linkedin
      })
    }
    if (!!value.social_media.instagram) {
      socialMedia.push({
        icon: IconEnums['icon-instagram'],
        url: value.social_media.instagram
      })
    }
    if (!!value.social_media.youtube) {
      socialMedia.push({
        icon: IconEnums['icon-youtube'],
        url: value.social_media.youtube
      })
    }
    if (!!value.social_media.app) {
      socialMedia.push({
        icon: IconEnums['icon-store'],
        url: value.social_media.app
      })
    }
  }
  return { socialMedia }
}

export default SocialMedia
