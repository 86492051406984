import { PAGE_TEMPLATE } from '../../services/api/constants'
import Hero from '../hero/YouFitnessTemplate/Hero'
import VideoBlock from '../blocks/YouFitnessTemplate/VideoBlock'
import ClubLocation from '../club-location/YouFitnessTemplate/ClubLocation'
import GymFeatures from '../gym-features/YouFitnessTemplate/GymFeatures'
import ImageGallery from '../image-gallery/YouFitnessTemplate/ImageGallery'
import PTrainers from '../personal-trainers/YouFitnessTemplate/PTrainers'
import SocialLinks from '../social-links/YouFitnessTemplate/SocialLinks'
import MainSection from '../main-section/YouFitnessTemplate/MainSection'
import Banner from '../banner/YouFitnessTemplate/Banner'
import KeyMessage from '../key-message/YouFitnessTemplate/KeyMessage'
import WhatsHot from '../whats-hot/YouFitnessTemplate/WhatsHot'
import Advertisement, {
  AdvertisementNewLayout
} from '../advertisement/YouFitnessTemplate/Advertisement'
import ContentBlock from '../blocks/YouFitnessTemplate/ContentBlock'
import HeroWithForm from '../hero/YouFitnessTemplate/HeroWithForm'
import IconSection from '../icon-section/YouFitnessTemplate/IconSection'
import GymOwnerBlock from '../blocks/YouFitnessTemplate/GymOwnerBlock'
import CTAPanels from '../blocks/YouFitnessTemplate/CTAPanels'
import CountdownBlock from '../blocks/YouFitnessTemplate/CountdownBlock'
import BlocksTable from '../blocks-table/YouFitnessTemplate/BlocksTable'
import SubHeader from '../header/YouFitnessTemplate/SubHeader'
import { FAQs } from '../faq/YouFitnessTemplate/FAQ'
import { ImageLink } from '../image-link/YouFitnessTemplate/ImageLink'
import HeaderWithTitle from '../header/YouFitnessTemplate/HeaderWithTitle'
import { WhyOwn } from '../why-own/YouFitnessTemplate/WhyOwn'
import Forms from '../forms/YouFitnessTemplate/Form'
import GymSuburbs from '../gym-suburbs/YouFitnessTemplate/GymSuburbs'
import AngledBanner from '../banner/YouFitnessTemplate/AngledBanner'
import Menu from '../menu/YouFitnessTemplate/Menu'
import GymDescription from '../gym-description/YouFitnessTemplate/GymDescription'
import { IconLinks } from '../icon-links'
import ImageAndText from '../image-and-text/YouFitnessTemplate'
import SocialMedia from '../social-media/YouFitnessTemplate'

const BlocksYouFitness = (name: string) => {
  switch (name) {
    case `gym_features${PAGE_TEMPLATE.YOU_FITNESS}`:
      return GymFeatures
    case `HeroWithForm${PAGE_TEMPLATE.YOU_FITNESS}`:
      return HeroWithForm
    case `Hero${PAGE_TEMPLATE.YOU_FITNESS}`:
    case `hero${PAGE_TEMPLATE.YOU_FITNESS}`:
    case `gym_hero${PAGE_TEMPLATE.YOU_FITNESS}`:
    case `BlogHeroSlide${PAGE_TEMPLATE.YOU_FITNESS}`:
      return Hero
    case `VideoEmbeds${PAGE_TEMPLATE.YOU_FITNESS}`:
      return VideoBlock
    case `gym_location${PAGE_TEMPLATE.YOU_FITNESS}`:
      return ClubLocation
    case `gym_description${PAGE_TEMPLATE.YOU_FITNESS}`:
      return GymDescription
    case `title_and_richtext${PAGE_TEMPLATE.YOU_FITNESS}`:
      return MainSection
    case `gym_social_links${PAGE_TEMPLATE.YOU_FITNESS}`:
      return SocialLinks
    case `gym_image_gallery${PAGE_TEMPLATE.YOU_FITNESS}`:
      return ImageGallery
    case `gym_personal_trainers${PAGE_TEMPLATE.YOU_FITNESS}`:
      return PTrainers
    case `banner${PAGE_TEMPLATE.YOU_FITNESS}`:
    case `Banner${PAGE_TEMPLATE.YOU_FITNESS}`:
    case `banner${PAGE_TEMPLATE.YOU_FITNESS}`:
      return Banner
    case `KeyMessage${PAGE_TEMPLATE.YOU_FITNESS}`:
      return KeyMessage
    case `WhatsHot${PAGE_TEMPLATE.YOU_FITNESS}`:
      return WhatsHot
    case `Advertisement${PAGE_TEMPLATE.YOU_FITNESS}`:
      return Advertisement
    case `ContentWithCTA${PAGE_TEMPLATE.YOU_FITNESS}`:
      return ContentBlock
    case `icon_section${PAGE_TEMPLATE.YOU_FITNESS}`:
    case `icons_section${PAGE_TEMPLATE.YOU_FITNESS}`:
      return IconSection
    case `icon_links${PAGE_TEMPLATE.YOU_FITNESS}`:
      return IconLinks
    case `GymOwners${PAGE_TEMPLATE.YOU_FITNESS}`:
      return GymOwnerBlock
    case `CTAPanels${PAGE_TEMPLATE.YOU_FITNESS}`:
      return CTAPanels
    case `CountdownBlock${PAGE_TEMPLATE.YOU_FITNESS}`:
      return CountdownBlock
    case `Table${PAGE_TEMPLATE.YOU_FITNESS}`:
      return BlocksTable
    case `Heading${PAGE_TEMPLATE.YOU_FITNESS}`:
      return SubHeader
    case `faq_section${PAGE_TEMPLATE.YOU_FITNESS}`:
      return FAQs
    case `title_and_image${PAGE_TEMPLATE.YOU_FITNESS}`:
      return ImageLink
    case `content${PAGE_TEMPLATE.YOU_FITNESS}`:
      return HeaderWithTitle
    case `ValueProposition${PAGE_TEMPLATE.YOU_FITNESS}`:
      return WhyOwn
    case `form${PAGE_TEMPLATE.YOU_FITNESS}`:
    case `Form${PAGE_TEMPLATE.YOU_FITNESS}`:
      return Forms
    case `angled_image_banner${PAGE_TEMPLATE.YOU_FITNESS}`:
      return AngledBanner
    case `gym_servicing_suburbs${PAGE_TEMPLATE.YOU_FITNESS}`:
      return GymSuburbs
    case `gym_social_links${PAGE_TEMPLATE.YOU_FITNESS}`:
      return SocialLinks
    case `GymPageMenu${PAGE_TEMPLATE.YOU_FITNESS}`:
    case `page_submenu${PAGE_TEMPLATE.YOU_FITNESS}`:
    case `BlogCategoryMenu${PAGE_TEMPLATE.YOU_FITNESS}`:
      return Menu
    case `advertisment${PAGE_TEMPLATE.YOU_FITNESS}`:
      return AdvertisementNewLayout
    case `image_and_text${PAGE_TEMPLATE.YOU_FITNESS}`:
      return ImageAndText
    case `SocalLinks${PAGE_TEMPLATE.YOU_FITNESS}`:
      return SocialMedia
  }
}

export default BlocksYouFitness
